import React, { useRef } from 'react';
import { cn } from '../../../helpers/composing';

interface Props {
    children?: React.ReactNode;
    showPopup: boolean;
}

function Popup(props: Props) {
    const { showPopup, children } = props;

    return (
        <div
            className={cn(
                'absolute left-10 z-50 flex min-w-[220px] flex-col items-center whitespace-nowrap rounded-[6px] border bg-white shadow-md',
                !showPopup ? 'hidden' : ''
            )}
        >
            {children}
        </div>
    );
}

export default Popup;
