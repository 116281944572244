import axios from 'axios';
import {
    GetFileParams,
    StampFileResponse,
    UploadFileBody,
    UploadFileResponse
} from './uploadFile.shema';
import { OnlineFormExampleAnswerParams } from '../onlineForm/onlineForm.shema';

export async function putUploadFile(body: UploadFileBody) {
    return axios.post<UploadFileResponse>('/v1/uploadfile', body, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function retrieveFile(params: GetFileParams) {
    return axios.get<UploadFileResponse>('/v1/getfile', { params });
}

export async function preStampFile(params: OnlineFormExampleAnswerParams) {
    return axios.get<UploadFileResponse>('/v1/pre-stampfile', {
        params
    });
}

export async function stampfile(params: OnlineFormExampleAnswerParams) {
    return axios.get<StampFileResponse>('/v1/stampfile', {
        params,
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}
