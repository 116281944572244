import {
    DatasourceReturnType,
    ResponseError
} from '../../models/common/respone.model';
import {
    callStartWorkflow,
    retrieveExampleAnswer,
    retrieveOnlineFormPosition
} from './onlineForm.datasource';
import {
    OnlineFormExampleAnswerParams,
    OnlineFormPositionParams,
    OnlineFormPositionResponse
} from './onlineForm.shema';

export async function getOnlineFormPositionBegin(
    params: OnlineFormPositionParams
): Promise<DatasourceReturnType<ResponseError, OnlineFormPositionResponse[]>> {
    try {
        const response = (await retrieveOnlineFormPosition(params)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}

export async function getExampleAnswer(
    params: OnlineFormExampleAnswerParams
): Promise<DatasourceReturnType<ResponseError, OnlineFormPositionResponse[]>> {
    try {
        const response = (await retrieveExampleAnswer(params)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}

export async function startWofkflow(
    data: OnlineFormPositionParams
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const response = (await callStartWorkflow(data)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}
