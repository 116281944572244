import { DatasourceReturnType } from '../../models/common/respone.model';
import { callApproveFormMapping } from './esignature.datasource';
import { CallApproveRequest, CallApproveResponse } from './esignature.shema';

export async function approveFormMapping(
    data: CallApproveRequest
): Promise<DatasourceReturnType<CallApproveResponse, string>> {
    try {
        const response = (await callApproveFormMapping(data)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}
