import {
    DatasourceReturnType,
    ResponseError
} from '../../models/common/respone.model';
import {
    putFilePosition,
    retrieveFilePosition
} from './saveFilePosition.datasource';
import {
    GetFilePositionParams,
    GetFilePositionResponse,
    PositionStampResponse,
    SaveFilePositionBody
} from './saveFilePosition.shema';

export async function saveFilePosition(
    body: SaveFilePositionBody
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const response = (await putFilePosition(body)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}

export async function getFilePosition(
    params: GetFilePositionParams
): Promise<DatasourceReturnType<ResponseError, PositionStampResponse>> {
    try {
        const response = (await retrieveFilePosition(params)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}
