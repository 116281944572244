import React, { useState } from 'react';
import { PositionStamp } from '../../models/mappingFile/MappingFile.model';

interface RectangleDrawerProps {
    pdfPosition: DOMRect;
    onRectangleDrawn: (position: PositionStamp) => string;
}

const RectangleDrawer: React.FC<RectangleDrawerProps> = ({
    pdfPosition,
    onRectangleDrawn
}) => {
    const [drawing, setDrawing] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [endX, setEndX] = useState(0);
    const [endY, setEndY] = useState(0);

    const handleMouseDown = (e: React.MouseEvent) => {
        setDrawing(true);
        setStartX(e.clientX - pdfPosition.left + window.scrollX);
        setStartY(e.clientY - pdfPosition.top + window.scrollY);
        setEndX(e.clientX - pdfPosition.left);
        setEndY(e.clientY - pdfPosition.top);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!drawing) return;

        setEndX(e.clientX - pdfPosition.left + window.scrollX);
        setEndY(e.clientY - pdfPosition.top + window.scrollY);
    };

    const handleMouseUp = () => {
        if (!drawing) return;

        setDrawing(false);
        const elementId = onRectangleDrawn({
            x: Math.min(startX, endX),
            y: Math.min(startY, endY),
            width: Math.abs(endX - startX),
            height: Math.abs(endY - startY),
            textLeft: 0,
            textTop: 0
        });

        // const rect = document.createElement('div');
        // rect.id = elementId;
        // rect.textContent = elementId;
        // rect.style.color = 'red';
        // rect.style.fontSize = '20px';
        // rect.style.position = 'absolute';
        // rect.style.display = 'flex';
        // rect.style.alignItems = 'center';
        // rect.style.justifyContent = 'center';
        // rect.style.border = '2px dashed red';
        // rect.style.left = `${Math.min(pdfPosition.x + startX)}px`;
        // rect.style.top = `${Math.min(pdfPosition.y + startY)}px`;
        // rect.style.width = `${Math.abs(endX - startX)}px`;
        // rect.style.height = `${Math.abs(endY - startY)}px`;

        // rect.style.left = `${Math.min(pdfPosition.x + startX)}px`;
        // rect.style.top = `${Math.min(pdfPosition.y + startY)}px`;
        // rect.style.width = `${Math.abs(endX - startX)}px`;
        // rect.style.height = `${Math.abs(endY - startY)}px`;

        // document.body.appendChild(rect);
    };

    return (
        <div
            style={{
                position: 'absolute',
                left: pdfPosition.left,
                top: pdfPosition.top,
                width: pdfPosition.width,
                height: pdfPosition.height
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
        >
            {drawing && (
                <div
                    style={{
                        position: 'absolute',
                        border: '2px dashed blue',
                        left: `${Math.min(startX, endX)}px`,
                        top: `${Math.min(startY, endY)}px`,
                        width: `${Math.abs(endX - startX)}px`,
                        height: `${Math.abs(endY - startY)}px`
                    }}
                />
            )}
        </div>
    );
};

export default RectangleDrawer;
