import React, { useRef, useState, useEffect } from 'react';
import { BlockPicker, ColorResult, RGBColor } from 'react-color';
import {
    RiArrowDropDownLine,
    RiDeleteBin6Line,
    RiEditBoxLine,
    RiSaveLine
} from 'react-icons/ri';

import {
    AiOutlineAlignCenter,
    AiOutlineAlignLeft,
    AiOutlineAlignRight
} from 'react-icons/ai';

import { TypeBoxPosition } from '../../../models/mappingFile/MappingFile.model';
import Popup from '../../../common/components/Popup/Popup';

type Props = {
    mappingPosition: TypeBoxPosition;
    handleEventBox: (
        element: TypeBoxPosition,
        mode: string,
        color?: string
    ) => void;
};

function CardHistory(props: Props) {
    const { mappingPosition, handleEventBox } = props;

    const [color, setColor] = useState<RGBColor>();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [showDetail, setShowDetail] = useState<boolean>(false);

    const popupRef = useRef<HTMLDivElement>(null);

    const handleColorChange = (newColor: ColorResult) => {
        setColor(newColor.rgb);

        handleEventBox(mappingPosition, 'color', newColor.hex);
    };

    const handleFontSizeChange = (fontSize: string) => {
        handleEventBox(mappingPosition, 'fontsize', fontSize);
    };

    const handleJustifyChange = (style: string) => {
        handleEventBox(mappingPosition, 'justify', style);
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    useEffect(() => {
        const handleDocumentClick = (e: MouseEvent) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(e.target as Node)
            ) {
                setIsPopupOpen(false);
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const colors: string[] = [
        '#00ffff',
        '#0000ff',
        '#bada55',
        '#065535',
        '#ffffff',
        '#C0C0C0',
        '#666666',
        '#000000',
        '#ffff00',
        '#ffa500',
        '#ff0000',
        '#ff80ed',
        '#8a2be2',
        '#800080',
        '#660066'
    ];

    const fontSize: number[] = [12, 14, 16, 18, 20, 22];

    return (
        <React.Fragment>
            <div
                className="flex items-center"
                ref={popupRef}
            >
                <RiArrowDropDownLine
                    className="h-5 w-5"
                    onClick={() => setShowDetail(prv => !prv)}
                />
                <span>name: {mappingPosition.text ?? mappingPosition.id}</span>
                <RiDeleteBin6Line
                    className="text-red-500"
                    onClick={() => {
                        handleEventBox(mappingPosition, 'delete');
                    }}
                />
                <RiEditBoxLine
                    className="text-green-500"
                    onClick={() => {
                        // if (isPopupOpen != true){
                            togglePopup();
                            handleEventBox(mappingPosition, 'edit');
                        // }
                    }}
                />
            </div>
            {showDetail && (
                <div className="ml-8 flex flex-col">
                    <span>type: {mappingPosition.type}</span>
                    <span>page: {mappingPosition.page}</span>
                    <span>font size: {mappingPosition.fontSize}</span>
                    <span>{`x: ${mappingPosition.position.x}, y: ${mappingPosition.position.y}, width: ${mappingPosition.position.width}, height: ${mappingPosition.position.height}`}</span>
                </div>
            )}

            <Popup showPopup={isPopupOpen}>
                    <div className="flex flex-col gap-2">
                        <BlockPicker
                            color={color}
                            colors={colors}
                            onChange={handleColorChange}
                        />
                        <span>Font Size</span>
                        <select
                            onChange={e => {
                                handleFontSizeChange(e.target.value);
                            }}
                        >
                            {fontSize.map((value, index) => (
                                <option
                                    value={value}
                                    key={index}
                                >
                                    {value}
                                </option>
                            ))}
                        </select>
                        <span>Justify Style</span>
                        <div className="flex flex-row gap-1">
                            <AiOutlineAlignLeft
                                onClick={() => {
                                    handleJustifyChange('start');
                                }}
                            />
                            <AiOutlineAlignCenter
                                onClick={() => {
                                    handleJustifyChange('center');
                                }}
                            />
                            <AiOutlineAlignRight
                                onClick={() => {
                                    handleJustifyChange('end');
                                }}
                            />
                        </div>
                        <button onClick={togglePopup}>close</button>
                    </div>
            </Popup>
        </React.Fragment>
    );
}

export default CardHistory;
