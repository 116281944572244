import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
    getFile,
    getPreStampFile,
    getStampFile
} from '../../data/uploadFile/uploadFile.repository';
import { UploadFileResponse } from '../../data/uploadFile/uploadFile.shema';
import {
    FileData,
    TypeBoxPosition
} from '../../models/mappingFile/MappingFile.model';
import useLoader from '../../hook/useLoader';
import { getFilePosition } from '../../data/saveFilePosition/saveFilePosition.repository';
import {
    getExampleAnswer,
    startWofkflow
} from '../../data/onlineForm/onlineForm.repository';
import Swal from 'sweetalert2';
import { approveFormMapping } from '../../data/esignature/esignature.repository';

interface FontMap {
    [fontStyle: string]: string;
}

function PreviewFileViewModel() {
    const loading = useLoader();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const answerCode = searchParams.get('ans');

    const [pageNumber, setPageNumber] = useState(1);
    const [boxPosition, setBoxPosition] = useState<TypeBoxPosition[]>([]);
    const [fileResponse, setFileResponse] = useState<UploadFileResponse>();
    const [font, setFont] = useState<string>('');
    const [preStamp, setPreStamp] = useState<boolean>(false);

    const file: FileData = useMemo(() => {
        const dataUrl = `data:application/pdf;base64,${fileResponse?.file}`;
        return {
            url: dataUrl,
            withCredentials: true
        };
    }, [fileResponse]);

    const fontMap: FontMap = {
        'TH Sarabun': 'font-thsarabun',
        'Angsana New': 'font-angsana',
        Leelawad: 'font-leelawad',
        Browalia: 'font-browalia'
    };

    useEffect(() => {
        if (id === null) {
            return;
        }

        getFileById();
    }, [id]);

    const getFileById = async () => {
        const [, response] = await getFile({ id: id! });

        if (response.file === null) {
            return;
        }
        setFileResponse({
            id: response.id,
            file: response.file,
            totalPage: response.totalPage
        });
        getPositionStampFile();
    };

    const getPositionStampFile = async () => {
        const [, response] = await getFilePosition({ id: id! });
        const selectedFont: string =
            fontMap[response.font_style] || 'font-thsarabun';

        setFont(selectedFont);
        if (answerCode === null || answerCode === 'esign') {
            setBoxPosition(response.file_position!);
        } else {
            const [, exampleAnswerResponse] = await getExampleAnswer({
                id: id!,
                answerCode: answerCode!
            });
            if (exampleAnswerResponse !== null) {
                const mappingBoxPosition = response.file_position!.map(
                    value => {
                        const matchingResponse = exampleAnswerResponse.find(
                            val => val.id === value.id
                        );
                        return matchingResponse
                            ? { ...value, text: matchingResponse.text }
                            : value;
                    }
                );

                setBoxPosition(mappingBoxPosition);
            }
        }
    };

    const nextPage = () => {
        updatePage(pageNumber + 1);
    };

    const prevPage = () => {
        updatePage(pageNumber - 1);
    };

    const normalPreview = async () => {
        setPreStamp(false);

        await getFileById();
    };

    const preStampFilePdf = async () => {
        loading.show();
        const [, response] = await getPreStampFile({
            id: id!,
            answerCode: answerCode!
        });

        setPreStamp(true);

        setFileResponse(prev => ({
            ...prev,
            id: response.id,
            file: response.file,
            totalPage: prev?.totalPage!
        }));
        loading.hide();
    };

    const stampFile = async () => {
        loading.show();
        const [, response] = await getStampFile({
            id: id!,
            answerCode: ''
        });

        if (response.status === 'Success') {
            await callStartWofkflow();
        }
        loading.hide();
    };

    const approvedFormMapping = async () => {
        loading.show();
        const [, response] = await getStampFile({
            id: id!,
            answerCode: answerCode!
        });

        if (response.status === 'Success') {
            const [, respone] = await approveFormMapping({ id: id! });

            window.location.replace(respone);
        }
        loading.hide();
    };

    const callStartWofkflow = async () => {
        const [, respone] = await startWofkflow({ id: id! });

        if (respone === 'Workflow started successfully') {
            Swal.fire({
                title: 'Success!',
                text: 'Workflow started successfully',
                icon: 'success',
                confirmButtonText: 'Ok!'
            }).then(async result => {
                if (result.isConfirmed) {
                    window.close();
                }
            });
        }
    };

    const updatePage = (newPageNumber: number) => {
        if (newPageNumber >= 1 && newPageNumber <= fileResponse?.totalPage!) {
            togglePageElements(pageNumber, 'none');
            setPageNumber(newPageNumber);
            togglePageElements(newPageNumber, 'flex');
        }
    };

    const togglePageElements = (page: number, display: string) => {
        const elementsOnPage = boxPosition.filter(value => value.page === page);
        elementsOnPage.forEach(val => {
            const box = document.getElementById(val.id);
            if (box) {
                box.style.display = display;
            }
        });
    };

    return {
        id,
        file,
        font,
        preStamp,
        answerCode,
        pageNumber,
        boxPosition,
        fileResponse,

        prevPage,
        nextPage,
        stampFile,
        normalPreview,
        preStampFilePdf,
        approvedFormMapping
    };
}

export default PreviewFileViewModel;
