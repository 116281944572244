import React, { useRef, memo } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { Document, Page, pdfjs } from 'react-pdf';
import CardHistory from './component/CardHistory';
import MappingFileViewModel from './MappingFile.viewmodel';

import RectangleDrawer from '../../common/components/RectangleDrawer';
import Moveable from 'react-moveable';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MemoDocument = memo(({ file, pageNumber }: any) => {
    return (
        <Document
            file={file}
            className={`my-pdf-document`}
        >
            <Page pageNumber={pageNumber} />
        </Document>
    );
});

function MappingFileView() {
    const {
        font,
        file,
        boxType,
        targetRef,
        fontStyle,
        pageNumber,
        boxPosition,
        pdfPosition,
        drawingMode,
        fontShowStyle,
        uploadFileParams,
        uploadFileResponse,

        nextPage,
        prevPage,
        handleFinish,
        handleDrawnPdf,
        handleEventBox,
        handleFileChange,
        handleUploadFile,
        handleRectangleDrawn,
        handleChangeCheckBox,
        handleFontStyleChange,
        handleSavePositionStamp,
        handleSavePositionState
    } = MappingFileViewModel();

    const moveableRef = useRef<Moveable | null>(null);

    return (
        <React.Fragment>
            <div className="flex gap-5">
                <div className="flex max-h-[700px] min-w-[422px] flex-col items-start gap-2 rounded bg-white p-2">
                    <span>Tool</span>
                    {uploadFileResponse === undefined && (
                        <div>
                            <input
                                type="file"
                                onChange={handleFileChange}
                            />
                            <button
                                className={`rounded-md border-slate-500 ${
                                    uploadFileParams !== undefined
                                        ? 'bg-green-600'
                                        : 'bg-gray-400'
                                } px-4 py-2 text-white`}
                                onClick={handleUploadFile}
                                disabled={uploadFileParams === undefined}
                            >
                                Upload File
                            </button>
                        </div>
                    )}
                    <span>File Id: {uploadFileResponse?.id ?? ''}</span>
                    <div>
                        <input
                            type="checkbox"
                            title="txt"
                            disabled={boxType === 'img'}
                            checked={boxType == 'txt'}
                            onChange={e => {
                                handleChangeCheckBox(e);
                            }}
                        />
                        <span className="mx-2">Text Box</span>
                        <input
                            type="checkbox"
                            title="img"
                            disabled={boxType === 'txt'}
                            onChange={e => {
                                handleChangeCheckBox(e);
                            }}
                        />
                        <span className="mx-2">Image</span>
                    </div>
                    <div>
                        Font Style:{' '}
                        <select
                            onChange={e => {
                                handleFontStyleChange(e.target.value);
                            }}
                            value={font}
                        >
                            {fontStyle.map((value, index) => (
                                <option key={index}>{value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="flex w-full flex-row justify-between">
                        <button
                            className="flex flex-row items-center justify-center gap-1"
                            onClick={() => {
                                handleDrawnPdf();
                            }}
                        >
                            <FaPencilAlt />
                            วาด
                        </button>
                        <button
                            className="rounded bg-green-100 px-4 py-2"
                            onClick={handleSavePositionStamp}
                        >
                            Save
                        </button>
                        <button
                            className="rounded bg-green-600 px-4 py-2 text-white"
                            onClick={handleFinish}
                        >
                            Finish
                        </button>
                    </div>
                    <div className="min-h-[300px] w-full overflow-auto border border-black p-2">
                        <span>History</span>
                        <div className="mb-2 w-full border border-gray-500" />
                        {boxPosition.map((value, index) => (
                            <CardHistory
                                key={index}
                                mappingPosition={value}
                                handleEventBox={handleEventBox}
                            />
                        ))}
                    </div>
                    {uploadFileResponse?.totalPage! > 1 && (
                        <div className="flex gap-2">
                            <button
                                onClick={prevPage}
                                className="rounded bg-green-50 p-2"
                            >
                                Previous Page
                            </button>
                            <button
                                onClick={nextPage}
                                className="rounded bg-green-50 p-2"
                            >
                                Next Page
                            </button>
                        </div>
                    )}
                </div>
                {uploadFileResponse && uploadFileResponse?.file !== null && (
                    <React.Fragment>
                        <style>{pdfCss}</style>
                        <div className="relative border border-black">
                            {boxPosition.map((element, index) => {
                                if (element.page === pageNumber)
                                    return (
                                        <div
                                            key={index}
                                            id={element.id}
                                            style={{
                                                left: element.position.x,
                                                top: element.position.y,
                                                width: element.position.width,
                                                height: element.position.height,
                                                justifyContent:
                                                    element.alignItem
                                            }}
                                            className={`absolute z-50 flex items-center border-2 border-dashed border-red-600`}
                                        >
                                            <span
                                                className={` ${fontShowStyle}`}
                                                style={{
                                                    fontSize: `${element.fontSize}px`,
                                                    color: element.textColor
                                                }}
                                            >
                                                {element.text ?? element.id}
                                            </span>
                                        </div>
                                    );
                            })}
                            <MemoDocument
                                file={file}
                                pageNumber={pageNumber}
                            />
                            <Moveable
                                ref={moveableRef}
                                target={targetRef}
                                draggable={true}
                                bounds={{
                                    left: 0,
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'css'
                                }}
                                edgeDraggable={false}
                                resizable={true}
                                snappable={true}
                                origin={false}
                                onDrag={e => {
                                    e.target.style.left = e.left + 'px';
                                    e.target.style.top = e.top + 'px';
                                }}
                                onDragEnd={e => {
                                    handleSavePositionState(e.target);
                                }}
                                onResize={e => {
                                    e.target.style.width = `${e.width}px`;
                                    e.target.style.height = `${e.height}px`;
                                }}
                                onResizeEnd={e => {
                                    handleSavePositionState(e.target);
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}
                <div className="flex flex-1 items-center justify-center">
                    {drawingMode && (
                        <RectangleDrawer
                            onRectangleDrawn={handleRectangleDrawn}
                            pdfPosition={pdfPosition!}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

const pdfCss = `
    .my-pdf-document .react-pdf__Page {
    display: block !important;
    }

    .my-pdf-document .react-pdf__Page > *:not(.react-pdf__Page__canvas) {
    display: none !important;
    }
`;

export default MappingFileView;
