import React, { memo, useRef } from 'react';
import PreviewFileViewModel from './PreviewFile.viewmodel';
import { Document, pdfjs, Page } from 'react-pdf';
import { useReactToPrint } from 'react-to-print';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MemoDocument = memo(({ file, pageNumber }: any) => {
    return (
        <Document
            file={file}
            className={`my-pdf-document`}
        >
            <Page pageNumber={pageNumber} />
        </Document>
    );
});

function PreviewFileView() {
    const {
        id,
        file,
        font,
        preStamp,
        pageNumber,
        answerCode,
        boxPosition,
        fileResponse,

        nextPage,
        prevPage,
        stampFile,
        normalPreview,
        preStampFilePdf,
        approvedFormMapping
    } = PreviewFileViewModel();

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    return (
        <React.Fragment>
            <div className="flex flex-row gap-5">
                <div className="flex max-h-[300px] min-w-[300px] flex-col items-center justify-center gap-2 rounded bg-white p-2">
                    <button
                        onClick={handlePrint}
                        className="rounded bg-purple-50 p-2"
                    >
                        Print
                    </button>
                    <button
                        onClick={() => {
                            window.location.replace(`/?id=${id}`);
                        }}
                        className="rounded bg-blue-50 p-2"
                    >
                        Back To Home
                    </button>
                    <div className="flex gap-2">
                        <button
                            onClick={normalPreview}
                            className="rounded bg-violet-50 p-2"
                        >
                            Preview
                        </button>
                        <button
                            onClick={preStampFilePdf}
                            hidden={
                                answerCode === null || answerCode !== 'esign'
                            }
                            className="rounded bg-yellow-50 p-2"
                        >
                            Preview Stamp File
                        </button>
                        <button
                            onClick={stampFile}
                            // hidden={answerCode !== null}
                            className="rounded bg-yellow-50 p-2"
                        >
                            Confirm Sign Form
                        </button>
                        <button
                            onClick={approvedFormMapping}
                            // hidden={answerCode !== null}
                            className="rounded bg-yellow-50 p-2"
                        >
                            Confirm ApproveForm
                        </button>
                    </div>
                    {fileResponse?.totalPage! > 1 && (
                        <div className="flex gap-2">
                            <button
                                onClick={prevPage}
                                className="rounded bg-green-50 p-2"
                            >
                                Previous Page
                            </button>
                            <button
                                onClick={nextPage}
                                className="rounded bg-green-50 p-2"
                            >
                                Next Page
                            </button>
                        </div>
                    )}
                </div>
                <div className="flex items-center justify-center">
                    <div ref={componentRef}>
                        <style>{pdfCss}</style>
                        <div className="relative border border-black">
                            {!preStamp &&
                                boxPosition.map((element, index) => {
                                    if (element.page === pageNumber)
                                        return (
                                            <div
                                                key={index}
                                                id={element.id}
                                                style={{
                                                    left: element.position.x,
                                                    top: element.position.y,
                                                    width: element.position
                                                        .width,
                                                    height: element.position
                                                        .height,
                                                    justifyContent:
                                                        element.alignItem
                                                }}
                                                className={`absolute z-50 flex items-center`}
                                            >
                                                <span
                                                    className={`${font} font-medium`}
                                                    style={{
                                                        fontSize: `${element.fontSize}px`,
                                                        color: element.textColor
                                                    }}
                                                >
                                                    {element.text ?? element.id}
                                                </span>
                                            </div>
                                        );
                                })}
                            <MemoDocument
                                file={file}
                                pageNumber={pageNumber}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const pdfCss = `
    .my-pdf-document .react-pdf__Page {
    display: block !important;
    }

    .my-pdf-document .react-pdf__Page > *:not(.react-pdf__Page__canvas) {
    display: none !important;
    }
`;

export default PreviewFileView;
