import axios from 'axios';
import {
    OnlineFormExampleAnswerParams,
    OnlineFormPositionParams,
    OnlineFormPositionResponse
} from './onlineForm.shema';

export async function retrieveOnlineFormPosition(
    params: OnlineFormPositionParams
) {
    return axios.get<OnlineFormPositionResponse[]>('/v1/getpositionbegin', {
        params
    });
}

export async function retrieveExampleAnswer(
    params: OnlineFormExampleAnswerParams
) {
    return axios.get<OnlineFormPositionResponse[]>('/v1/getexampleAnswer', {
        params
    });
}

export async function callStartWorkflow(body: OnlineFormPositionParams) {
    return axios.post<string>('/v1/callstartworkflow', body, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}
