import {
    preStampFile,
    putUploadFile,
    retrieveFile,
    stampfile
} from './uploadFile.datasource';
import {
    GetFileParams,
    StampFileResponse,
    UploadFileBody,
    UploadFileResponse
} from './uploadFile.shema';
import {
    DatasourceReturnType,
    ResponseError
} from '../../models/common/respone.model';
import { OnlineFormExampleAnswerParams } from '../onlineForm/onlineForm.shema';

export async function uploadFile(
    body: UploadFileBody
): Promise<DatasourceReturnType<ResponseError, UploadFileResponse>> {
    try {
        const response = (await putUploadFile(body)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}

export async function getFile(
    params: GetFileParams
): Promise<DatasourceReturnType<ResponseError, UploadFileResponse>> {
    try {
        const response = (await retrieveFile(params)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}

export async function getPreStampFile(
    params: OnlineFormExampleAnswerParams
): Promise<DatasourceReturnType<ResponseError, UploadFileResponse>> {
    try {
        const response = (await preStampFile(params)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}

export async function getStampFile(
    params: OnlineFormExampleAnswerParams
): Promise<DatasourceReturnType<ResponseError, StampFileResponse>> {
    try {
        const response = (await stampfile(params)).data;

        return [null!, response];
    } catch (error) {
        throw error;
    }
}
