import axios from 'axios';

import { CallApproveRequest } from './esignature.shema';

export async function callApproveFormMapping(body: CallApproveRequest) {
    console.log('TEll;');

    return axios.post<string>('/v1/callapprove', body, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}
