import React from 'react';
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements
} from 'react-router-dom';
import Dashboard from '../common/components/Dashboard';
import MappingFileView from '../presentations/MappingFile/MappingFile.view';
import PreviewFileView from '../presentations/PreviewFile/PreviewFile.view';
// import TestPage from '../presentations/testpage/testpage';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const routerOptions = { basename: baseUrl! };

const router = createBrowserRouter(
    createRoutesFromElements(
        <React.Fragment>
            <Route
                path="/"
                element={<Dashboard />}
            >
                <Route
                    path="/"
                    element={<MappingFileView />}
                />
                <Route
                    path="/previewfile"
                    element={<PreviewFileView />}
                />
                {/* <Route
                    path="/test"
                    element={<TestPage />}
                /> */}
            </Route>
        </React.Fragment>
    ),
    routerOptions
);

export default router;
