import Swal from 'sweetalert2';
import { useEffect, useMemo, useRef, useState } from 'react';

import useLoader from '../../hook/useLoader';
import {
    TypeBoxPosition,
    PositionStamp,
    FileData
} from '../../models/mappingFile/MappingFile.model';
import {
    getFile,
    uploadFile
} from '../../data/uploadFile/uploadFile.repository';
import {
    UploadFileBody,
    UploadFileResponse
} from '../../data/uploadFile/uploadFile.shema';
import {
    getFilePosition,
    saveFilePosition
} from '../../data/saveFilePosition/saveFilePosition.repository';
import { useSearchParams } from 'react-router-dom';
import { OnlineFormPositionResponse } from '../../data/onlineForm/onlineForm.shema';
import { getOnlineFormPositionBegin } from '../../data/onlineForm/onlineForm.repository';

interface FontMap {
    [fontStyle: string]: string;
}

function MappingFileViewModel() {
    const loading = useLoader();

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const token = searchParams.get('token');
    //#region upload pdf
    const [pageNumber, setPageNumber] = useState(1);
    const [uploadFileResponse, setUploadFileResponse] =
        useState<UploadFileResponse>();
    const [uploadFileParams, setUploadFileParams] = useState<UploadFileBody>();
    const file: FileData = useMemo(() => {
        const dataUrl = `data:application/pdf;base64,${uploadFileResponse?.file}`;
        return {
            url: dataUrl,
            withCredentials: true
        };
    }, [uploadFileResponse]);

    const [targetRef, setTargetRef] = useState<HTMLElement | null>(null);
    //#endregion

    //#region drawing
    const [drawingMode, setDrawingMode] = useState(false);
    const [boxPosition, setBoxPosition] = useState<TypeBoxPosition[]>([]);
    const pdfPosition = new DOMRect(461.53125, 20, 603, 900);
    const [boxId, setBoxId] = useState<string>();
    const [boxType, setBoxType] = useState<string>('txt');
    //#endregion

    const [font, setFont] = useState<string>('TH Sarabun');
    const [fontShowStyle, setFontShowStyle] = useState<string>('');

    const fontMap: FontMap = {
        'TH Sarabun': 'font-thsarabun',
        'Angsana New': 'font-angsana',
        Leelawad: 'font-leelawad',
        Browalia: 'font-browalia'
    };

    const fontStyle: string[] = [
        'TH Sarabun',
        'Angsana New',
        'Leelawad',
        'Browalia'
    ];

    useEffect(() => {
        if (token !== null) {
            const key = 'SSO_AUTH';
            localStorage.setItem(key, token!);
        }
        const getFileById = async () => {
            const [, response] = await getFile({ id: id! });
            if (response.file !== null) {
                setUploadFileResponse({
                    id: response.id,
                    file: response.file,
                    totalPage: response.totalPage
                });
            }
            getPositionStampFile();
        };

        const getPositionStampFile = async () => {
            const [, response] = await getFilePosition({ id: id! });
            if (response.file_position === null) {
                getPositionBegin();

                return;
            }

            const selectedFont: string =
                fontMap[response.font_style] || 'font-thsarabun';

            console.log(response.file_position);
            setFont(response.font_style);
            setFontShowStyle(selectedFont);
            setBoxPosition(response.file_position!);
        };

        const getPositionBegin = async () => {
            const [, response] = await getOnlineFormPositionBegin({ id: id! });
            const mappedData: TypeBoxPosition[] = response.map(value => ({
                id: value.id,
                type: value.type,
                page: value.page,
                position: {
                    x: 0,
                    y: 0,
                    width: 100,
                    height: 100,
                    textLeft: 0,
                    textTop: 0
                },
                textColor: '#000000',
                text: value.text,
                fontSize: '12',
                alignItem: 'start',
                fontStyle: 'font-thsarabun'
            }));

            setBoxPosition(mappedData);
        };

        getFileById();
    }, [id !== null]);

    const handleChangeCheckBox = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setBoxType(event.target.checked ? event.target.title : '');
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        setUploadFileParams({ id: id!, file: file! });
    };

    const handleUploadFile = async () => {
        loading.show();
        const [, response] = await uploadFile(uploadFileParams!);
        setUploadFileResponse({
            id: response.id,
            file: response.file,
            totalPage: response.totalPage
        });
        setPageNumber(1);
        loading.hide();
    };

    const handleRectangleDrawn = (position: PositionStamp) => {
        setDrawingMode(false);
        setBoxPosition([
            ...boxPosition,
            {
                id: boxId!,
                type: boxType,
                page: pageNumber,
                position: position,
                textColor: '#000000',
                fontSize: '12',
                alignItem: 'start',
                fontStyle: 'font-thsarabun'
            }
        ]);

        return boxId!;
    };

    const handleDrawnPdf = () => {
        Swal.fire({
            title: 'What is your box name',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Ok!',
            showLoaderOnConfirm: true,
            preConfirm: value => {
                setBoxId(value);
            }
        });

        setDrawingMode(true);
    };

    const handleSavePositionStamp = async (): Promise<string | undefined> => {
        try {
            loading.show();
            // const updatedPositions = boxPosition.map(element => {
            //     const elementBox = document.getElementById(element.id);
            //     const spanElement = elementBox?.getElementsByTagName('span');
            //     if (elementBox) {
            //         return {
            //             ...element,
            //             position: {
            //                 x: parseFloat(elementBox.style.left || '0'),
            //                 y: parseFloat(elementBox.style.top || '0'),
            //                 width: elementBox.clientWidth + 4,
            //                 height: elementBox.clientHeight + 4,
            //                 textLeft: spanElement?.item(0)?.offsetLeft,
            //                 textTop: spanElement?.item(0)?.offsetTop
            //             }
            //         };
            //     }

            //     return element;
            // });

            const resultBoxPositionString = JSON.stringify(boxPosition);

            const [_, response] = await saveFilePosition({
                id: uploadFileResponse?.id!,
                font_style: font,
                position: resultBoxPositionString
            });

            return response;
        } catch {
        } finally {
            loading.hide();
        }
    };

    const nextPage = () => {
        updatePage(pageNumber + 1);
    };

    const prevPage = () => {
        updatePage(pageNumber - 1);
    };

    const updatePage = (newPageNumber: number) => {
        if (
            newPageNumber >= 1 &&
            newPageNumber <= uploadFileResponse?.totalPage!
        ) {
            togglePageElements(pageNumber, 'none');
            setPageNumber(newPageNumber);
            togglePageElements(newPageNumber, 'flex');
        }
    };

    const togglePageElements = (page: number, display: string) => {
        const elementsOnPage = boxPosition.filter(value => value.page === page);
        elementsOnPage.forEach(val => {
            const box = document.getElementById(val.id);
            if (box) {
                box.style.display = display;
            }
        });
    };

    const handleFinish = async () => {
        try {
            loading.show();
            const response = await handleSavePositionStamp();

            if (response === 'success') {
                window.location.replace(
                    `/previewfile?id=${uploadFileResponse?.id}&ans=esign`
                );
            }
        } catch {
        } finally {
            loading.hide();
        }
    };

    const handleFontStyleChange = (fontStyle: string) => {
        const selectedFont: string = fontMap[fontStyle] || 'font-thsarabun';

        setFont(fontStyle);
        setFontShowStyle(selectedFont);
    };

    const handleSavePositionState = (elementBox: HTMLElement | SVGElement) => {
        let textTop = 0;
        let textLeft = 0;

        boxPosition.map(value => {
            if (elementBox?.id === value.id) {
                const elementDiv = document.getElementById(elementBox?.id);
                const spanElement = elementDiv?.getElementsByTagName('span');
                textLeft = spanElement?.item(0)?.offsetLeft!;
                textTop = spanElement?.item(0)?.offsetTop!;
            }
        });

        setBoxPosition(prevState =>
            prevState.map(element =>
                element.id === elementBox.id
                    ? {
                          ...element,
                          position: {
                              x: parseFloat(elementBox.style.left || '0'),
                              y: parseFloat(elementBox.style.top || '0'),
                              width: elementBox.clientWidth + 4,
                              height: elementBox.clientHeight + 4,
                              textLeft: textLeft,
                              textTop: textTop
                          }
                      }
                    : element
            )
        );
    };

    const handleEventBox = (
        element: TypeBoxPosition,
        mode: string,
        style?: string
    ) => {
        const elementBox = document.getElementById(element.id);

        if (mode === 'edit' && elementBox) {
            elementBox.style.zIndex = '100';
            setTargetRef(elementBox!);
        }
        if (mode === 'color') {
            setBoxPosition(prevBoxPosition =>
                prevBoxPosition.map(element =>
                    element.id === elementBox?.id
                        ? {
                              ...element,
                              textColor: style!
                          }
                        : element
                )
            );
        }
        if (mode === 'fontsize') {
            setBoxPosition(prevBoxPosition =>
                prevBoxPosition.map(element =>
                    element.id === elementBox?.id
                        ? {
                              ...element,
                              fontSize: style!
                          }
                        : element
                )
            );
        }
        if (mode === 'delete') {
            for (let i = 0; i < boxPosition.length; i++) {
                if (boxPosition[i] === element) {
                    setBoxPosition(prev => {
                        const nextState = [...prev];
                        nextState.splice(i, 1);

                        return nextState;
                    });

                    return;
                }
            }
        }
        if (mode === 'justify') {
            setBoxPosition(prevBoxPosition =>
                prevBoxPosition.map(element =>
                    element.id === elementBox?.id
                        ? {
                              ...element,
                              alignItem: style!
                          }
                        : element
                )
            );

            handleSavePositionState(elementBox!);
        }
    };

    return {
        font,
        file,
        boxType,
        targetRef,
        fontStyle,
        pageNumber,
        boxPosition,
        drawingMode,
        pdfPosition,
        fontShowStyle,
        uploadFileParams,
        uploadFileResponse,

        nextPage,
        prevPage,
        handleFinish,
        handleDrawnPdf,
        handleEventBox,
        handleUploadFile,
        handleFileChange,
        handleFontStyleChange,
        handleRectangleDrawn,
        handleChangeCheckBox,
        handleSavePositionState,
        handleSavePositionStamp
    };
}

export default MappingFileViewModel;
