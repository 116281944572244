import axios from 'axios';
import {
    GetFilePositionParams,
    GetFilePositionResponse,
    PositionStampResponse,
    SaveFilePositionBody
} from './saveFilePosition.shema';

export async function putFilePosition(body: SaveFilePositionBody) {
    return axios.post('/v1/savepositionstamp', body, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function retrieveFilePosition(params: GetFilePositionParams) {
    return axios.get<PositionStampResponse>('/v1/getpositionstamp', {
        params
    });
}
